<template>
    <v-bottom-sheet v-model="showFilters" scrollable>
        
          <v-card> 
            <v-card-title class="pt-7">
              <v-spacer/>
              <v-icon color="primary">mdi-filter</v-icon>
              <span class="headline ml-3 ">Фільтри</span>
              <v-spacer/>
            </v-card-title>
            
            <v-card-text >
              <v-container>
                <v-row :justify="$vuetify.breakpoint.smAndDown ? 'start' : 'center'">
                  
                  <v-col v-for="(item, key) in filters" :key="key" :cols="item.col_sm ? 4:12" :sm="item.col_sm ? 2:6" :md="item.col_sm ? 2:4" :lg="item.col_sm ? 2:3" :xl="item.col_sm ? 1:2" :class="{'d-none': item.name == 'user_worker' || item.name == 'not_pon' }">
                    <v-switch 
                      v-if="item.type == 'boolean'" 
                      v-model="item.value" class="ma-2" 
                      :label="item.text" 
                      :disabled="item.disable"
                      :dense="$vuetify.breakpoint.smAndDown"
                      >
                      
                    </v-switch>
                   
                    <v-select 
                      v-else-if="item.type == 'select'"
                      @keyup.enter="apply"
                      menu-props="auto"
                      v-model="item.value"
                      :items="item.values"
                      item-text="name"
                      item-value="id"
                      :label="item.text"
                      no-data-text="Немає данних"
                      :multiple="item.multiple"
                      :disabled="item.disable"
                      
                      :dense="$vuetify.breakpoint.smAndDown"
                      clearable
                      persistent-hint
                    >
                      <!--<template v-if="item.name == 'user_worker' || item.name == 'user_recive'" v-slot:item="{ item }">
                          {{ item.first_name }} {{ item.last_name }}
                      </template>
                      <template v-if="item.name == 'user_worker' || item.name == 'user_recive'" v-slot:selection="{ item }">
                          {{ item.first_name }} {{ item.last_name }}
                      </template>-->
                      <!--<template v-if="!item.multiple" v-slot:item="{ item }">
                        <v-icon left :color="item.color">{{ item.icon }}</v-icon>{{ item.name }}
                      </template>-->
                      <template v-if="item.name == 'tarif'" v-slot:item="{ item }">
                        <div style="width: 100%;" class="d-flex justify-space-between">
                          <span>{{ item.name }}</span>
                          <DetailTarifForFilters :tarif="item" />
                        </div>
                      </template>
                    </v-select>

                    <template v-else-if="item.type == 'autocomplete' && item.name == 'street'" >
                      <keep-alive>
                      <SearchStreet v-on:search-new-id="setSearchValue" iconForm="mdi-database-search" :clearSearchStreet="clearSearchStreet" :denseForm="$vuetify.breakpoint.smAndDown"/>
                      </keep-alive>
                    </template>
                      
                    
                    
                    <v-checkbox
                      v-else-if="item.type == 'checkbox'"
                      v-model="item.value"
                      :label="item.text"
                      :disabled="item.disable"
                      :dense="$vuetify.breakpoint.smAndDown"
                    ></v-checkbox>
                    
                    <v-text-field 
                      v-else @keyup.enter="apply" 
                      v-model="item.value" 
                      :label="item.text" 
                      :type="item.type"
                      :disabled="item.disable"
                      :dense="$vuetify.breakpoint.smAndDown"
                      min="0"
                    ></v-text-field>

                  </v-col>
                  
                </v-row>
              </v-container>
            </v-card-text>
            <v-card-actions>
              <v-spacer/>
              <v-btn color="primary lighten-1" text class="mx-3" @click="reset()">Скинути</v-btn>
              <v-btn color="primary" :class="$vuetify.theme.dark ? '' : 'lighten-1'" class="mx-3" @click="apply">Пошук</v-btn>
              <v-btn color="primary lighten-1" text class="mx-3" @click="close">Закрити</v-btn>
              <v-spacer/>
            </v-card-actions>
            <div :class="$vuetify.breakpoint.smAndDown ? 'my-4':'my-6'"> </div>
          </v-card>
        
    </v-bottom-sheet>
</template>

<script>


function firstDay(){
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth();
    return new Date(year, month, 1).toDateInputValue()
}

const filters = { 
  "abonents": [ 
      {"name": "street",  "value": "",    "type": "autocomplete","col_sm": false, "text": "Вулиця",   "multiple": false, "values": []},
      {"name": "house",   "value": "",    "type": "number",      "col_sm": true,  "text": "Будинок"},
      //{"name": "corpus",   "value": "",    "type": "number",      "col_sm": true,  "text": "Корпус"},
      {"name": "letter",  "value": "",    "type": "select",      "col_sm": true,  "text": "Літера",   "multiple": false, values: ['А','Б','В','Г','К']},
      {"name": "flat",    "value": "",    "type": "number",      "col_sm": true,  "text": "Квартира"},
      //{"name": "tenand",   "value": "",    "type": "number",      "col_sm": true,  "text": "Наймач"},
      {"name": "tarif",   "value": "",    "type": "select",      "col_sm": false, "text": "Тариф",    "multiple": false, values: []},
      {"name": "distributor",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Постачальник", "multiple": false, values: []},
      {"name": "service",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Послуга", "multiple": false, values: []},
      //{"name": "status",  "value": false, "type": "checkbox",    "col_sm": false, "text": "Прибрати відключених (ТБ)" },
      {"name": "status",  "value": false, "type": "select",    "col_sm": false, "text": "Статус", values: [{id: "true", name: 'Підключені (ТБ)'}, {id: "false", name: 'Відключені (ТБ)'}, {id: 'active', name: "Підключені (Інтернет)"}, {id: 'not_active', name: "Відключені (Інтернет)"}] },

      //{"name": "is_online",  "value": false, "type": "checkbox",    "col_sm": true, "text": "Онлайн" },
      {"name": "is_credit",  "value": false, "type": "select",    "col_sm": false, "text": "Кредит", values: [{id: "credit_tv", name: 'Кредит ТБ'}, {id: true, name: "Кредит Інтернет"}] },
      {"name": "district",  "value": false, "type": "select",    "col_sm": false, "text": "Дільниця", "multiple": false, values: [] },
      {"name": "is_organization",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Організація","multiple": false, values: [{id: "true", name: 'Тільки організації'}, {id: "false", name: "Без організацій"}]},
      {"name": "tags",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Теги","multiple": true, values: []},
      {"name": "operator_group","value": "",    "type": "select",      "col_sm": false, "text": "Оператор", "multiple": false, values: []},
      {"name": "discount",  "value": false, "type": "checkbox",    "col_sm": false, "text": "Знижка" },

      
  ],
  "debtors": [
      {"name": "street",     "value": "",    "type": "autocomplete","col_sm": false, "text": "Вулиця",          "multiple": false, "values": []},
      {"name": "house",      "value": "",    "type": "number",      "col_sm": true,  "text": "Будинок"},
      {"name": "letter",     "value": "",    "type": "select",      "col_sm": true,  "text": "Літера",          "multiple": false, values: ['А','Б','В','Г','К']},
      {"name": "flat",       "value": "",    "type": "number",      "col_sm": true,  "text": "Квартира"},
      {"name": "tarif",      "value": "",    "type": "select",      "col_sm": false, "text": "Тариф",           "multiple": false, values: []},
      {"name": "is_two_play","value": "",    "type": "select",      "col_sm": false, "text": "Подвійна послуга","multiple": false, values: [{id: "true", name: 'Тільки ПП'}, {id: "false", name: "Без ПП"}]},
      
      {"name": "debtor",  "value": "",    "type": "select",      "col_sm": false, "text": "Кількість тарифів", "multiple": false, values: [1,2,3,4,5,6,7,8,9,10]},
      
      //{"name": "status",  "value": false, "type": "checkbox",    "col_sm": false, "text": "Прибрати відключених" }
      {"name": "operator_group","value": "",    "type": "select",      "col_sm": false, "text": "Оператор", "multiple": false, values: []},
    ],
  "pays": [
      {"name": "start_date","value": new Date().toDateInputValue(), "type": "date", not_close_for_chip: true,   "text": "Дата від"},
      {"name": "end_date",  "value": "", "type": "date", not_close_for_chip: true,   "text": "Дата до"},
      {"name": "type_pay",  "value": "", "type": "select", "col_sm": false, disable: false, "text": "Тип платежу", "multiple": true, "values": []},
      //{"name": "service",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Послуга", "multiple": false, values: []},
      {"name": "distributor",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Постачальник", "multiple": false, values: []},
      {"name": "district",  "value": false, "type": "select",    "col_sm": false, "text": "Дільниця", "multiple": false, values: [] },
      {"name": "is_organization",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Організація","multiple": false, values: [{id: "true", name: 'Тільки організації'}, {id: "false", name: "Без організацій"}]},
      {"name": "manual",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Ручне проведення", },
      {"name": "operator_group",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Оператор", "multiple": false, values: []},
    ],
  "pays_abonent": [
      {"name": "start_date","value": new Date().toDateYear(), "type": "date",   "text": "Дата від"},
      {"name": "end_date",  "value": "", "type": "date",   "text": "Дата до"},
      {"name": "type_pay",  "value": "", "type": "select", "col_sm": false, disable: false, "text": "Тип платежу", "multiple": true, "values": []},
      //{"name": "service",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Послуга", "multiple": false, values: []},
      {"name": "manual",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Ручне проведення", },
      {"name": "distributor",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Постачальник", "multiple": false, values: []},
      {"name": "operator_group",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Оператор", "multiple": false, values: []},
    ],
  "job": [
      {"name": "street",  "value": "",    "type": "autocomplete","col_sm": false, "text": "Вулиця",   "multiple": false, "values": []},
      {"name": "house",   "value": "",    "type": "number",      "col_sm": false,  "text": "Будинок"},
      {"name": "letter",  "value": "",    "type": "select",      "col_sm": false,  "text": "Літера",   "multiple": false, values: ['А','Б','В','Г','К']},
      {"name": "start_date_due", "value": "", "type": "date",   "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "end_date_due",   "value": "", "type": "date",   "col_sm": false, disable: false,  "text": "Дата до"},
      {"name": "order_service",  "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Послуга", "multiple": false, values: []},
      {"name": "order_type",     "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Тип",     "multiple": false, values: []},
      {"name": "date_recive",    "value": "", "type": "date",   "col_sm": false, disable: false,  "text": "Дата створення"},
      {"name": "user_recive",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Прийняв", "multiple": false, values: []},
      {"name": "order_status",   "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Статус",  "multiple": false, values: []},
      {"name": "order_worker__worker",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Виконавець","multiple": false, values: []},
      
      {"name": "date_done",      "value": "", "type": "date",   "col_sm": false, disable: false,  "text": "Дата виконання"},
      {"name": "need_check",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Необроблені","multiple": false, values: [{id: "true", name: 'Тільки необроблені'}, {id: "false", name: "Без необроблених"}]},
      {"name": "district",  "value": false, "type": "select",    "col_sm": false, "text": "Дільниця", "multiple": false, values: [] },
      {"name": "user_worker",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "__","multiple": false, values: [] },
      {"name": "not_pon",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Без PON","multiple": false, values: [] },
      {"name": "userless",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Заявки без користувача","multiple": false },
      {"name": "tags",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Теги","multiple": true, values: []},
      {"name": "operator_group",       "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Оператор", "multiple": true, values: []},
    ],
  "notifications": [
      {"name": "start_date_due", "value": "", "type": "date",   "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "end_date_due",   "value": new Date().toDateInputValue(), "type": "date",   "col_sm": false, disable: false,  "text": "Дата до"},
      {"name": "user_recive",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Створив", "multiple": false, values: []},
      {"name": "operator_group", "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Оператор", "multiple": true, values: []},
    ],
  "calls": [
      {"name": "date_start",    "value": new Date().toDateInputValue(), "type": "date", "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "date_end",    "value": "", "type": "date", "col_sm": false, disable: false,  "text": "Дата до"},
      //{"name": "phone_number",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Телефони", "multiple": true, filter_type: 'multiple-phones', not_close_for_chip: true, values: []},
      {"name": "state_owner",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Прийняв", "multiple": false, values: []}, 
      {"name": "is_incoming",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Тип дзвінків","multiple": false, values: [{id: "true", name: 'Вхідні'}, {id: "false", name: "Вихідні"}]},
      {"name": "is_missed",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Тільки пропущені" },
  ],
  "calls_abonent": [
      {"name": "date_start",    "value": "", "type": "date", "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "date_end",    "value": "", "type": "date", "col_sm": false, disable: false,  "text": "Дата до"},
      {"name": "phone_number",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Телефони", "multiple": true, filter_type: 'multiple-phones', not_close_for_chip: true, values: []},
      {"name": "state_owner",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Прийняв", "multiple": false, values: []}, 
      {"name": "is_incoming",    "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Тип дзвінків","multiple": false, values: [{id: "true", name: 'Вхідні'}, {id: "false", name: "Вихідні"}]},
      {"name": "is_missed",    "value": "", "type": "checkbox", "col_sm": false, disable: false,  "text": "Тільки пропущені" },
  ],
  "connects_abonent": [
      {"name": "start_date",    "value": firstDay(), "type": "date", "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "end_date",    "value": "", "type": "date", "col_sm": false, disable: false,  "text": "Дата до"},
  ],
  "novakom_bank": [
      {"name": "start_date", "value": new Date().toDateInputValue(), "type": "date", "col_sm": false, disable: false,  "text": "Дата від"},
      {"name": "end_date", "value": new Date().toDateInputValue(), "type": "date", "col_sm": false, disable: false,  "text": "Дата до"},
      //{"name": "bank", "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Банк"},
      {"name": "service", "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Сервіс", values: [{id: 1, name: 'Телебачення'}, {id: 2, name: "Інтернет"}]},
      {"name": "is_recived", "value": "", "type": "select", "col_sm": false, disable: false,  "text": "Статус", values: [{id: "true", name: 'Надійшли'}, {id: "false", name: "Не надійшли"}]},
      
  ],
  
}
import { mapGetters } from "vuex"
import SearchStreet from '@/components/Tools/SearchStreet.vue'
import DetailTarifForFilters from '@/components/AbonentInfo/TabInfoCards/DetailTarifForFilters.vue'

export default {
    name: "filters",
    props: ['for'],
    components: {
      SearchStreet,
      DetailTarifForFilters
    },
    data: () => ({
        showFilters: false,
        filters: null,
        clearSearchStreet: 0
    }),
    computed: {
      ...mapGetters({
        user: "auth/user",
        operators: "filters/operators",
        districts: "filters/districts_with_none",
        pays_type: "filters/pays_type",
        //streets: "filters/streets",
        rates: "filters/rates",
        operators_groups: "filters/operators_groups",
        job_type: "job_types/type",
        job_service: "job_types/service",
        job_status:"job_types/status",
        users: "users/users",
        call_centre_users: "users/call_centre_users",
        tags: "tags/tags",
        order_tags: "tags/order_tags"

      }),
    },
    created: function () {
      //
    },
    watch: {
      //
    },
    mounted () {
      this.filters = JSON.parse(JSON.stringify(filters[this.for]));
      //console.log("mounted Filters", this.for)
      this.createFilters()
      //console.log("mounted filters")
      
    },
    activated(){
      if(this.for == 'pays_abonent') this.filters.find(x => x.name == "start_date").value = new Date().toDateYear()
      if(this.for == 'connects_abonent') this.filters.find(x => x.name == "start_date").value = firstDay()
      //if (this.for == 'debtors') this.filters.find(x => x.name == "debtor").value = 2
    },
    methods: {
        show(){
            //this.createFilters()
            this.showFilters = true;
        },
        close(){
            this.showFilters = false;
        },
        reset(){
            this.clearSearchStreet += 1
            this.filters.forEach(element => { element.value = "" });
            if (this.for == 'pays'){
              this.filters.find(x => x.name == "start_date").value = new Date().toDateInputValue() // При сбросе фильтров платежей - ставим дефолтный период даты платежей: 1 день
              this.filters.find(x => x.name == "end_date").value = new Date().toDateInputValue() // При сбросе фильтров платежей - ставим дефолтный период даты платежей: 1 день
            }
            if (this.for == 'job'){
              //this.filters.find(x => x.name == "operator_group").value = [1,2]
              this.filters.find(x => x.name == "start_date_due").value = this.$router.app.generateStartDate(); // При сбросе фильтров заявок - ставим дефолтный период даты заявок: 1 месяц 
            }
            if (this.for == 'connects_abonent'){
              this.filters.find(x => x.name == "start_date").value = firstDay(); 
            }
            if (this.for == 'notifications'){
              this.filters.find(x => x.name == "end_date_due").value = new Date().toDateInputValue() // При скиданні фільтрів повідомлень - ставим дефолтний період даты: 1 день
            }
            if (this.for == 'calls'){
              this.filters.find(x => x.name == "date_start").value = this.$router.app.generateStartDate(); // При сбросі фільтрів дзвінків - ставим дефолтний період дати дзвінків: 1 місяць
            }
            if (this.for == 'debtors'){
              //this.filters.find(x => x.name == "debtor").value = 2; // При сбросе фильтров боржників - ставим кількість тарифів боргу 2
            }

            this.apply();
        },
        apply(){
          //console.log( this.filters.find(x => x.name == "operator_group").value)
          console.log(this.filters)
          this.$emit('apply-filters');
          this.filters.forEach(element => { element.disable = false }); // Убираем ограничение на выбор фильтров (если был шаблон)
          this.showFilters = false;
          
        },
        createFilters(){
          if(this.for == 'connects_abonent') return
          //console.log('createFilters')
          let operatorsIndex = this.filters.indexOf(this.filters.find(x => x.name == "operator_group"))
          let distributorIndex = this.filters.indexOf(this.filters.find(x => x.name == "distributor"))
          
          
          if(this.for == 'abonents'){
            this.filters.find(x => x.name == "tarif").values = this.rates
            this.filters.find(x => x.name == "service").values = this.job_service
            this.filters.find(x => x.name == "district").values = this.districts
            this.filters.find(x => x.name == "tags").values = this.tags
            
            if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
              this.filters[distributorIndex].values = this.operators
            }else{
              let operatorDistributorIndex = this.operators_groups.indexOf(this.operators_groups.find(x => x.id == this.user.operator_id))
              this.filters[distributorIndex].values = this.operators_groups[operatorDistributorIndex].distributor
            }
          }else if(this.for == 'debtors'){
            this.filters.find(x => x.name == "tarif").values = this.rates
          }else if(this.for == 'pays' || this.for == 'pays_abonent'){
            this.filters.find(x => x.name == "type_pay").values = this.pays_type
            //this.filters.find(x => x.name == "service").values = this.job_service
            
            if(this.for == 'pays') this.filters.find(x => x.name == "district").values = this.districts
            
            if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
              this.filters[distributorIndex].values = this.operators
            }else{
              let operatorDistributorIndex = this.operators_groups.indexOf(this.operators_groups.find(x => x.id == this.user.operator_id))
              this.filters[distributorIndex].values = this.operators_groups[operatorDistributorIndex].distributor
            }
          }else if(this.for == 'job'){
            this.filters.find(x => x.name == "order_type").values = this.job_type
            this.filters.find(x => x.name == "order_service").values = this.job_service
            this.filters.find(x => x.name == "order_status").values = this.job_status
            this.filters.find(x => x.name == "user_recive").values = this.users.filter(x => x.user_type == 1 || x.user_type == 3 || x.user_type == 5)
            this.filters.find(x => x.name == "user_worker").values = this.users.filter(x => x.user_type == 2 )
            this.filters.find(x => x.name == "order_worker__worker").values = this.users.filter(x => x.user_type == 2 )
            this.filters.find(x => x.name == "district").values = this.districts
            this.filters.find(x => x.name == "tags").values = this.order_tags
          }else if(this.for == 'notifications'){
            this.filters.find(x => x.name == "user_recive").values = this.users.filter(x => x.user_type == 1 || x.user_type == 3 || x.user_type == 5)
          }
          else if(this.for == 'novakom_bank'){
            return
          }

          if(this.for == 'calls_abonent' || this.for == 'calls') {
            
            this.filters.find(x => x.name == "state_owner").values = this.call_centre_users.filter(x => x.call_centre && !x.on_duty)

            let on_duty_user = this.call_centre_users.find(x => x.on_duty && x.call_centre)
            if(on_duty_user){
              on_duty_user.name = "Черговий"
              this.filters.find(x => x.name == "state_owner").values.push(on_duty_user)
            }
            
            return
          }
          

          if(this.user?.operator_id == 1){ // Якщо оператор "Altair Group"
            this.filters[operatorsIndex].values = this.operators_groups
          }else{
            this.filters.splice(operatorsIndex, 1)
          }
          this.$emit('create-filters');
        },
        setSearchValue(val, name){
          //console.log("This is a setSearchValue", val, name)
            this.filters.find(x => x.name == "street").value = val
            this.filters.find(x => x.name == "street").street_name = name

        }
      },
    
}

</script>